import { decode } from "js-base64";
import isBase64Lib from "is-base64";
import imageCompression from "browser-image-compression";

/*******************************************************************
  Utilities
*******************************************************************/

export const copyObject = (mainObj) => {
  // let objCopy = {}; // objCopy will store a copy of the mainObj
  // let key;
  // for (key in mainObj) {
  //   objCopy[key] = mainObj[key]; // copies each property to the objCopy object
  // }
  return JSON.parse(JSON.stringify(mainObj));
};

/******************************************
 *  Array
 *******************************************/

export const last = (array) => {
  if (array.length > 0) {
    return array[array.length - 1];
  }
  return null;
};

/******************************************
 *  Guid
 *******************************************/

/**
 * Method to generate a guid. Used for syncID.
 */
export const getGuid = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  var guid =
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4();
  return guid;
};

/******************************************
 * Boolean
 *******************************************/

/**
 * With cordova the check on true is not working in the same way.
 * Wrapper to have constant behaviour.
 * @param {*} value
 */
export const isTrue = (value) => {
  return value === true || value === 1 || value === "true";
};

/**
 * With cordova the check on true is not working in the same way.
 * Wrapper to have constant behaviour.
 * @param {*} value
 */
export const toggleBoolean = (value) => {
  if (value === 1) {
    return 0;
  }
  if (value === 0) {
    return 1;
  }
  if (value === "true") {
    return "false";
  }
  if (value === "false") {
    return "true";
  }
  if (value === true) {
    return false;
  }
  if (value === false) {
    return true;
  }
};

/******************************************
 * Empty
 *******************************************/

export const isEmpty = (value) => {
  return typeof value === "undefined" || value === null || value === "";
};

export const isEmptyArray = (value) => {
  if (value) {
    if (Array.isArray(value) && value.length > 0) {
      return false;
    } else return true;
  } else return true;
};

export const isEmptyObj = (value) => {
  return (
    isEmpty(value) || // 👈 null and undefined check
    (Object.keys(value).length === 0 &&
      Object.getPrototypeOf(value) === Object.prototype)
  );
};

/******************************************
 * Convertions of types
 *******************************************/
export const roundToTwo = (num) => {
  return +(Math.round(num + "e+2") + "e-2");
};

export const isNumeric = (str) => {
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
};

export const jsonDateToString = (date) => {
  var ts = date;
  ts = ts.replace("/Date(", "");
  ts = ts.replace(")/", "");
  ts = Number(ts); // cast it to a Number
  var newDate = new Date(ts); // works
  if (newDate === undefined || newDate === null) {
    return "";
  }
  return newDate.toLocaleDateString("Be-nl");
};

/**
 * Adds an integer to a px string and returns the px string
 * @param {} value : 200px
 * @param {*} sum :  50
 * Result 250px
 */
export const addIntToPixelString = (value, sum) => {
  var numberValue = Number(value.replace(/px$/, ""));
  numberValue = numberValue + sum;
  var resultString = numberValue.toString().concat("px");
  return resultString;
};

/**
 * Adds an integer to a px string and returns the px string
 * @param {} value : 200px
 * @param {*} sum :  50
 * Result 250px
 */
export const pixelStringToInt = (value) => {
  var numberValue = Number(value.replace(/px$/, ""));
  return numberValue;
};

export const bearerIsValid = (bearer, bearerValidUntil) => {
  var now = new Date();
  return bearer !== null && bearerValidUntil > now;
};

export const getDefaultValueForType = (type) => {
  if (type === "integer") {
    return 0;
  } else if (type === "boolean" || type === "Boolean") {
    return false;
  } else {
    return "";
  }
};

export const extractEmail = (from) => {
  if (from) {
    const re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    return re.exec(from)[0];
  }
  return null;
};

/******************************************
 * Object Specific
 *******************************************/
export const latestObject = (objects, field) => {
  var latestObject = null;
  var previous = null;
  objects.array.forEach((object) => {
    if (previous === null || object[field] > previous[field]) {
      latestObject = object;
    }
    previous = object;
  });
  return latestObject;
};

export const splitFields = (object, fieldString) => {
  var fields = fieldString.split("###");
  var display = "";
  var delimiter = "";
  fields.forEach((field) => {
    var followingField = object[field];
    if (followingField !== undefined) {
      display = display.concat(delimiter).concat(followingField);
    }
    delimiter = " ";
  });
  return display;
};

/******************************************
 * Saving Errors
 *******************************************/

export const findSavingError = (objects, objectConfig, dataBase) => {
  objects.forEach((object) => {
    checkIfPropertiesOfObjectInFieldConfig(objectConfig, object, "field");
    checkKeyFieldsAreNotInitial(objectConfig, object);
  });
  checkNoDuplicates(objectConfig, objects);
  checkIfPropertiesInTableDefinition(objectConfig, objects, dataBase);
};

export const checkKeyFieldsAreNotInitial = (objectConfig, object) => {
  var keyfields = objectConfig.fieldsConfig.filter((fieldConfig) =>
    isTrue(fieldConfig.isId),
  );
  var fieldsNotOk = false;
  keyfields.forEach((keyField) => {
    if (isEmpty(object[keyField.field])) {
      fieldsNotOk = true;
      console.log(
        "Keyfield: ",
        keyField,
        " is Empty for object",
        object,
        object[keyField.field],
      );
    }
  });
  if (!fieldsNotOk) {
    // console.log('All fields ok for object', object, keyfields);
  }
};

export const checkNoDuplicates = (objectConfig, objects) => {
  var keyfields = objectConfig.fieldsConfig.filter((fieldConfig) =>
    isTrue(fieldConfig.isId),
  );
  var duplicateFound = false;
  var currentObject;
  for (var i = 0; i < objects.length; i++) {
    currentObject = objects[i];
    var sameObjects = objects.filter((filteredObject) => {
      for (var j = 0; j < keyfields.length; j++) {
        var keyfield = keyfields[j].field;
        if (currentObject[keyfield] !== filteredObject[keyfield]) {
          return false;
        }
      }
      return true;
    });
    if (sameObjects.length > 1) {
      console.log(
        "Following ",
        objectConfig.objectType,
        " objects have the same keys: ",
        keyfields[0].field,
        " : ",
        sameObjects[0][keyfields[0].field],
        sameObjects,
      );
    }
  }
};

export const checkIfPropertiesOfObjectInFieldConfig = (
  objectConfig,
  object,
  fieldField,
) => {
  var fieldsNotOk = false;
  for (var prop in object) {
    if (Object.prototype.hasOwnProperty.call(object, prop)) {
      if (!checkIfPropertyInFieldConfig(objectConfig, prop, fieldField)) {
        fieldsNotOk = true;
        console.log(
          "Object has wrong field compared to field config",
          object,
          prop,
        );
      }
    }
  }
  if (!fieldsNotOk) {
    // console.log('All fields ok for config', object, objectConfig);
  }
};

export const checkIfPropertyInFieldConfig = (
  objectConfig,
  field,
  fieldField,
) => {
  var fieldFound = objectConfig.fieldsConfig.find(
    (object) => object[fieldField] === field,
  );
  if (!isEmpty(fieldFound)) {
    return true;
  }
  return false;
};

export const checkIfPropertyInTableConfig = (
  objectConfig,
  field,
  fieldField,
) => {
  var fieldFound = null; //Todo Must be retrieved from the table.
  if (!isEmpty(fieldFound)) {
    return true;
  }
  return false;
};

export const checkIfPropertiesInTableDefinition = (
  objectConfig,
  objects,
  dataBase,
) => {
  console.log("getDBINfoCheck");
  objectConfig.getDataBaseInfo(dataBase);
};

const cleanNumber = (n) => {
  if (!isEmpty(n) && (typeof n === "string" || n instanceof String)) {
    const removeUnwantedDots = n.replace(/\,/g, ".");
    return removeUnwantedDots.replace(/[.](?=.*[.])/g, "");
  }
  return n;
};

export const formatNumber = (number, currency = "EUR") => {
  if (number !== null && number !== undefined) {
    const newNumber = cleanNumber(number);
    return new Intl.NumberFormat("nl-BE", {
      style: "currency",
      currency: currency,
    }).format(newNumber);
  } else return number;
};

export const roundNumber = (number, round) => {
  if (number !== null && number !== undefined) {
    if (round === null || round === undefined || round <= 0)
      return Math.round(number);
    return Math.round(number * Math.pow(10, round)) / Math.pow(10, round);
  } else return number;
};

export const convertToNumber = (string) => {
  if (isEmpty(string)) {
    return 0;
  }
  if (string.includes(",")) {
    string.replace(",", ".");
  }
};

export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (error) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const isBase64 = (string) => {
  if (!isEmpty(string)) {
    return isBase64Lib(string, { mimeRequired: true });
  }
  return false;
};

export const isBlob = (string) => {
  var MyBlob = new Blob([string], { type: "text/plain" });
  return MyBlob instanceof Blob;
};

export const b64decode = (base64) => {
  // // Replace URL-safe characters with standard base64 characters
  base64 = base64.replace(/-/g, "+").replace(/_/g, "/");
  // Pad the string with "=" characters as necessary
  while (base64.length % 4 !== 0) {
    base64 += "=";
  }
  // Decode the base64 string
  const decoded = atob(base64);
  // Convert the decoded string to a Uint8Array
  var bytes = new Uint8Array(decoded.length);
  for (var i = 0; i < decoded.length; i++) {
    bytes[i] = decoded.charCodeAt(i);
  }
  return bytes;
};

export const urlsafe_b64decode = (encoded) => {
  return decode(encoded);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const b64toBlob = (dataURI) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
};

export const arrayBufferToBase64 = (buffer) => {
  return btoa(
    new Uint8Array(buffer).reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, ""),
  );
};

export const isSame = (value1, value2) => {
  if (!isNaN(value1)) {
    var number1 = Number(value1);
  }
  if (!isNaN(value2)) {
    var number2 = Number(value2);
  }
  if (!isEmpty(number1) && !isEmpty(number2)) {
    return number1 === number2;
  }
  return value1 === value2;
};

export const isEmail = (email) => {
  if (isEmpty(email)) {
    return false;
  }
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const isPhone = (phone) => {
  if (isEmpty(phone)) {
    return false;
  }
  return true;
  return String(phone)
    .toLowerCase()
    .match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);
};

export const HOURS = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

export const QUARTERS = ["00", "15", "30", "45"];

export const getTimesOptions = (from) => {
  let startHourIndex = 0;
  let startMinuteIndex = 0;
  let isDuration = false;
  if (from && typeof from === "string") {
    const fromSplitted = from.split(":");
    const hour = Array.isArray(fromSplitted) ? from.split(":")[0] : null;
    const minute = Array.isArray(fromSplitted) ? from.split(":")[1] : null;
    const hourIndex = HOURS.indexOf(hour);
    const minutIndex = QUARTERS.indexOf(minute);
    if (hour && hourIndex > -1) {
      startHourIndex = hourIndex;
      isDuration = true;
    }
    if (minute && minutIndex > -1) {
      startMinuteIndex = minutIndex;
      isDuration = true;
    }
  }
  let result = [];
  for (let h = 0, hl = HOURS.length; h < hl; h++) {
    if (startHourIndex + h >= HOURS.length) startHourIndex = -h;

    const step = h == 0 || !isDuration ? 1 : 2;
    for (let m = 0, ml = QUARTERS.length; m < ml; m += step) {
      const curIndex = startMinuteIndex + m;
      const isNextHour = curIndex >= QUARTERS.length;

      const mIndex = isNextHour ? curIndex - QUARTERS.length : curIndex;
      const minute = QUARTERS[mIndex];
      const addHour = isNextHour ? h + 1 : h;
      const hour =
        HOURS[
          startHourIndex + addHour >= HOURS.length
            ? 0
            : startHourIndex + addHour
        ];
      let duration = null;
      if (isDuration)
        duration = {
          amount: h == 0 ? m * 15 : h + (m === 0 ? 0 : 0.5),
          scale: h == 0 ? "minutes" : "hours",
          label: h == 0 ? `${m * 15} min` : `${h}${m === 0 ? "" : ",5"} hour`,
        };
      result.push({
        value: `${hour}:${minute}`,
        duration: duration,
      });
    }
  }
  return result;
};

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    // console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      // console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    },
  );
};

export function convertClassesToInline(body) {
  var cssStr = extractCss(body);
  var htmlStr = extractBody(body);
  // Create a temporary div element to parse the HTML
  var tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlStr;

  // Find all elements with a 'class' attribute
  var elementsWithClasses = tempDiv.querySelectorAll("[class]");

  // Parse CSS
  const styleId = "MAIL_SWIFTIO_STYLE_ID";
  var styleSheet =
    document.getElementById(styleId) || document.createElement("style");
  styleSheet.setAttribute("id", styleId);
  styleSheet.innerHTML = cssStr;
  document.head.appendChild(styleSheet);

  // Iterate through elements and replace classes with inline styles
  elementsWithClasses.forEach(function (element) {
    var classList = Array.from(element.classList);

    // Retrieve styles from CSS for each class
    classList.forEach(function (className) {
      var rule = findCssRule(styleSheet.sheet, `.${className}`);

      if (rule) {
        applyCssRule(element, rule);
      }
    });

    // Remove the 'class' attribute
    element.removeAttribute("class");
  });

  styleSheet.innerHTML = "";

  // Return the modified HTML
  return tempDiv.innerHTML;
}

function findCssRule(styleSheet, selector) {
  for (var i = 0; i < styleSheet.cssRules.length; i++) {
    var rule = styleSheet.cssRules[i];
    console.log(rule, `.${selector}`);
    if (rule.selectorText === selector) {
      return rule;
    }
  }
  return null;
}

function applyCssRule(element, rule) {
  var styleDeclaration = rule.style;
  for (var i = 0; i < styleDeclaration.length; i++) {
    var property = styleDeclaration[i];
    element.style[property] = styleDeclaration.getPropertyValue(property);
  }
}

export function extractCss(htmlString) {
  var parser = new DOMParser();
  // Parse the HTML string into a Document object
  var doc = parser.parseFromString(htmlString, "text/html");
  // Access the style tag
  var styleTags = doc.querySelectorAll("style");
  let css = "";
  if (styleTags) {
    for (let index = 0; index < styleTags.length; index++) {
      const tag = styleTags[index];
      if (index == 0) {
        css = tag.innerHTML;
      } else {
        css += ` ${tag.innerHTML}`;
      }
    }
  }
  return css;
}

export function extractBody(htmlString) {
  var parser = new DOMParser();
  // Parse the HTML string into a Document object
  var doc = parser.parseFromString(htmlString, "text/html");
  // Access the style tag
  var styleTag = doc.querySelector("body");
  // Get the content of the style tag
  return styleTag.innerHTML;
}

export const formatHtmlData = (template, data, templates) => {
  const placeholderRegex = /{{\s*([^}]+)\s*}}/g;
  const templateRegex = /{#template:(.*?)}/g;
  const ifElsePattern = /{#if:(.*?)}(.*?)(?:{#else}(.*?))?{\/if}/gs;
  const eachRegex = /{#each:(.*?)}/g;
  const eachPattern = /{#each.*?}(.*?){\/each}/gs;

  template = template.replace(placeholderRegex, (_, key) => {
    const keys = key.split(".");
    let value = data;
    keys.forEach((k) => {
      value = value && value[k];
    });
    return value === undefined ? _ : value === null ? "" : value;
  });

  template = template.replace(eachPattern, (_, html) => {
    const eachMatch = eachRegex.exec(template);
    if (!eachMatch || !eachMatch[1]) return _;
    const key = eachMatch[1];
    const dataArray = data[key];
    if (!dataArray) return _;
    return dataArray
      .map((item) => formatHtmlData(html, item, templates))
      .join("");
  });

  template = template.replace(
    ifElsePattern,
    (_, key, ifContent, elseContent) => {
      const keys = key.split(".");
      let value = data;
      keys.forEach((k) => {
        value = value && value[k];
      });
      if (isTrue(value)) {
        return formatHtmlData(ifContent, data, templates);
      } else if (elseContent) {
        return formatHtmlData(elseContent, data, templates);
      }
      return "";
    },
  );

  template = template.replace(templateRegex, (_, templateId) => {
    const childTemplate = templates.find(
      (item) => item.TemplateId === templateId,
    );
    return childTemplate && childTemplate.Body
      ? formatHtmlData(childTemplate.Body, data, templates)
      : "";
  });

  return template;
};

export const replaceImageSrcWithDataURL = async (htmlContent) => {
  // Regular expression to match <img> tags with src attribute
  const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;

  // Match all <img> tags in the HTML content
  const imgTags = htmlContent.match(imgSrcRegex);

  if (imgTags) {
    // Array to store promises for fetching image data
    const imagePromises = [];

    // Iterate through each <img> tag
    for (const imgTag of imgTags) {
      // Extract the URL from the src attribute
      const srcMatches = imgTag.match(/src="([^"]+)"/);
      if (srcMatches && srcMatches.length > 1) {
        const imageUrl = srcMatches[1];

        // Fetch image data and push promise to array
        const promise = fetch(imageUrl)
          .then((response) => response.blob())
          .then((imageData) => {
            // Convert image data to data URL
            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(imageData);
              reader.onloadend = async function () {
                const dataURL = reader.result;
                const imageFile = dataURLtoFile(dataURL, "pdf-image");
                const compressed = await compressImage(imageFile);
                const newDataUrl = await blobToDataURL(compressed);
                // Replace the original src attribute with the data URL
                htmlContent = htmlContent.replace(imageUrl, newDataUrl);
                resolve();
              };
            });
          })
          .catch((error) => {
            console.error(`Error fetching image: ${imageUrl}`, error);
          });

        imagePromises.push(promise);
      }
    }

    // Wait for all image data fetching promises to resolve
    await Promise.all(imagePromises);
  }

  return htmlContent;
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

//**blob to dataURL**
export const blobToDataURL = (blob) => {
  var a = new FileReader();
  return new Promise((resolve, reject) => {
    a.onload = function (e) {
      resolve(e.target.result);
    };
    a.readAsDataURL(blob);
  });
};

export const compressImage = (file, maxWidth) => {
  return new Promise(async (resolve, reject) => {
    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: maxWidth || 1500,
      useWebWorker: true,
    };
    try {
      const blob = await imageCompression(file, options);
      const compressedFile = new File([blob], file.name, {
        type: file.type,
      });
      resolve(compressedFile);

      // write your own logic
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getInitials = (name) => {
  if (name) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

    let initials = [...name.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();
    return initials;
  }
};

export const getCurrencySign = (currency) => {
  if (currency === "EUR") {
    return "€";
  } else if (currency === "USD") {
    return "$";
  } else if (currency === "GBP") {
    return "£";
  }
  return "€";
};
